import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  padding: 0 30%;
  @media screen and (max-width: 768px) {
    padding: 8% 10%;
  }
  .label {
    color: #1c9d87;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    color: #142c1f;
    text-align: center;
  }
  .subtitle {
    font-size: 16px;
    color: #44554c;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .subtitle2 {
    font-size: 16px;
    color: #142c1f;
    text-align: center;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .image-row {
    display: flex;
    gap: 20px;
    margin-top: -10px;
    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      object-fit: contain;
    }
    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
      filter: grayscale(100%);
    }
  }
`;
