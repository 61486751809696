const RadioOptions = [
  { label: "Studioleiter", value: "Studioleiter" },
  { label: "Inhaber", value: "Inhaber" },
  { label: "Geschäftsführer", value: "Geschäftsführer" },
  { label: "Sonstiges", value: "Sonstiges" },
];
const companyRadioOptions = [
  {
    label: "Regelbesteuerung Deutschland",
    value: "Regelbesteuerung Deutschland",
  },
  {
    label: "Unternehmen im Drittland (außerhalb der EU)",
    value: "Unternehmen im Drittland",
  },
  { label: "Unternehmen im EU-Ausland", value: "Unternehmen im EU-Ausland" },
  {
    label: "Kleinunternehmer (§ 19 UStG, DE, USt-befreit)",
    value: "Kleinunternehmer",
  },
];

export { RadioOptions, companyRadioOptions };
