// onboarding pages
import _Start from "pages/onboarding/start/Start";
import _Gender from "pages/onboarding/gender/Gender";
import _Goal from "pages/onboarding/goal/Goal";
import _BodyDetails from "pages/onboarding/body-details/BodyDetails";
import _DailyRoutine from "pages/onboarding/daily-routine/DailyRoutine";
import _ActivityLevel from "pages/onboarding/activity-level/ActivityLevel";
import _TrainingDays from "pages/onboarding/training-days/TrainingDays";
import _MealCategories from "pages/onboarding/preferred-meals/MealCategories";
import _Meal from "pages/onboarding/preferred-meals/Meal";
import _TermsInfo from "pages/onboarding/terms-info/TermsInfo";
import _Payment from "pages/onboarding/payment/Payment";
import _NutritionType from "pages/onboarding/nutrition-type/NutritionType";
import _NutritionRecipes from "pages/onboarding/nutrition-recipes/NutritionRecipes";
import _WeeklyCalories from "pages/onboarding/weekly-calories/WeeklyCalories";
import _MacroNutrients from "pages/onboarding/macro-nutrients/MacroNutrients";
import _Done from "pages/onboarding/done/Done";
import _SetPassword from "pages/onboarding/set-password/SetPassword";

// !! IMPORTANT NOTE : the routes order here affects the next and back buttons in the onboarding
export const mealsRoute = "/onboarding/preferred-meals/:mealId";
const ONBOARDING_ROUTES = [
  { path: "/app-erstellen", component: _Start, progressStep: 0 },

  { path: "/onboarding/gender", component: _Gender, progressStep: 0 },
  { path: "/onboarding/goal", component: _Goal, progressStep: 0 },
  {
    path: "/onboarding/body-details",
    component: _BodyDetails,
    progressStep: 0,
  },
  {
    path: "/onboarding/daily-routine",
    component: _DailyRoutine,
    progressStep: 0,
  },
  {
    path: "/onboarding/activity-level",
    component: _ActivityLevel,
    progressStep: 0,
  },
  {
    path: "/onboarding/training-days",
    component: _TrainingDays,
    progressStep: 0,
  },

  {
    path: "/onboarding/preferred-meals/categories",
    component: _MealCategories,
    progressStep: 0,
  },
  { path: mealsRoute, component: _Meal, progressStep: 0 },
  { path: "/onboarding/terms-info", component: _TermsInfo, progressStep: 0 },
  { path: "/onboarding/payment", component: _Payment, progressStep: 2 },
  {
    path: "/onboarding/set-password",
    component: _SetPassword,
    progressStep: 1,
  },
  {
    path: "/onboarding/nutrition-type",
    component: _NutritionType,
    progressStep: 2,
  },
  {
    path: "/onboarding/nutrition-recipes",
    component: _NutritionRecipes,
    progressStep: 2,
  },
  {
    path: "/onboarding/weekly-calories",
    component: _WeeklyCalories,
    progressStep: 2,
  },
  {
    path: "/onboarding/macro-nutrients",
    component: _MacroNutrients,
    progressStep: 2,
  },
  { path: "/onboarding/done", component: _Done, progressStep: 2 },
];

export const getPreviousOnboardingPage = (currentPath) => {
  const currentPathIndex = ONBOARDING_ROUTES.findIndex(
    (route) => route.path === currentPath
  );
  const previousRoute = ONBOARDING_ROUTES[currentPathIndex - 1] || null;
  if (!previousRoute) return null;

  return previousRoute.path === mealsRoute
    ? mealsRoute.replace(":mealId", "5")
    : previousRoute.path;
};

export const getNextOnboardingPage = (currentPath) => {
  const currentPathIndex = ONBOARDING_ROUTES.findIndex(
    (route) => route.path === currentPath
  );

  const nextRoute = ONBOARDING_ROUTES[currentPathIndex + 1] || null;
  if (!nextRoute) return null;

  return nextRoute.path === mealsRoute
    ? mealsRoute.replace(":mealId", "1")
    : nextRoute.path;
};

export const getPreviousMealPage = (currentMeal) => {
  currentMeal = parseInt(currentMeal);

  if (currentMeal === 1) return getPreviousOnboardingPage(mealsRoute);
  return mealsRoute.replace(":mealId", currentMeal - 1);
};

export const getNextMealPage = (currentMeal) => {
  currentMeal = parseInt(currentMeal);

  if (currentMeal === 5) return getNextOnboardingPage(mealsRoute);
  return mealsRoute.replace(":mealId", currentMeal + 1);
};

// new onboarding routes ordered
export default ONBOARDING_ROUTES;
