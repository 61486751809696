import { takeLatest, put, all, call } from "redux-saga/effects";

import ApiCollections from "configs/services/apiCollections";

import AuthActionTypes from "redux/auth/auth.types";
import * as AuthActions from "./auth.actions";

/*************************************************/

export function* signIn({ payload }) {
  console.log("sssssssjdsajh");
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.signIn, payload);
    yield put(AuthActions.signInSuccess(response.data.token));
    yield put(
      AuthActions.setRegistrationData({
        trainerID: response.data.trainerID,
        FranchiseID: response.data.FranchiseID,
      })
    );
    localStorage.setItem("trainer_token", response.data.token);
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.signInFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* existingPlanSignIn({ payload }) {
  console.log("ss1");
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.existingPlanSignIn,
      payload.formData
    );
    yield put(AuthActions.signInExistingPlanSuccess(response.data.token));
    yield put(
      AuthActions.setRegistrationData({
        trainerID: payload.formData.trainerID,
        FranchiseID: payload.formData.FranchiseID,
        ...response.data.userData,
      })
    );
    localStorage.setItem("token", response.data.token);
    yield put(AuthActions.loadingStop());
    yield put(payload.func());
  } catch (error) {
    yield put(AuthActions.signInExistingPlanFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* trainerSignUp({ payload }) {
  console.log("ss2");
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.trainerSignUp, payload);
    yield put(AuthActions.trainerSignUpSuccess(response.data.token));
    yield put(
      AuthActions.setRegistrationData({
        trainerID: response.data.trainerID,
        FranchiseID: response.data.FranchiseID,
      })
    );
    localStorage.setItem("trainer_token", response.data.token);
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.trainerSignUpFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* changePassword({ payload }) {
  console.log("ss3");
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.changePassword,
      payload.formValues
    );

    yield put(AuthActions.changePasswordSuccess(response.message));
    //localStorage.clear();
    yield put(payload.func());
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.changePasswordFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* trainerDetailsEdit({ payload }) {
  console.log("ss4");
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.trainerDetailsEdit, payload);
    yield put(AuthActions.trainerProfileEditSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.trainerProfileEditFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* getTrainerProfile({ payload }) {
  console.log("ss5");
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(ApiCollections.getTrainerProfile, payload);
    yield put(AuthActions.getTrainerProfileSuccess(response.data));
    yield put(AuthActions.loadingStop());
  } catch (error) {
    yield put(AuthActions.getTrainerProfileFailure(error));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* registrationPart1({ payload }) {
  yield put(AuthActions.loadingStart());
  try {
    const response = yield call(
      ApiCollections.registrationPart1,
      payload.formValues
    );

    payload.callback(response);

    yield put(AuthActions.loadingStop());
    if (response.access_token) {
      localStorage.setItem("token", response.access_token);
      yield put(
        AuthActions.setRegistrationData({
          token: response.access_token,
          userID: response.$nutritionconcept.userID,
        })
      );
      yield put(
        AuthActions.registration1Success({
          token: response.access_token,
          userID: response.$nutritionconcept.userID,
        })
      );

      yield put(payload.func());
    } else {
      yield put(AuthActions.registration1Failure(response.message));
    }
  } catch (error) {
    yield put(AuthActions.registration1Failure(error.message));
    yield put(AuthActions.loadingStop());
  }
}

/*************************************************/

export function* signOut() {
  console.log("ss7");
  try {
    localStorage.clear();
    yield put(AuthActions.signOutSuccess());
  } catch (error) {
    yield put(AuthActions.signOutFailure("SignOut Failed"));
  }
}

/*************************************************/

export function* authSagas() {
  yield all([
    yield takeLatest(AuthActionTypes.SIGN_IN_REQUEST, signIn),
    yield takeLatest(
      AuthActionTypes.SIGN_IN_EXISTING_PLAN_REQUEST,
      existingPlanSignIn
    ),
    yield takeLatest(AuthActionTypes.SIGN_OUT_REQUEST, signOut),
    yield takeLatest(AuthActionTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    yield takeLatest(AuthActionTypes.TRAINER_SIGN_UP_REQUEST, trainerSignUp),
    yield takeLatest(
      AuthActionTypes.TRAINER_DETAILS_EDIT_REQUEST,
      trainerDetailsEdit
    ),
    yield takeLatest(
      AuthActionTypes.GET_TRAINER_PROFILE_REQUEST,
      getTrainerProfile
    ),
    yield takeLatest(
      AuthActionTypes.REGISTRATION_PART1_REQUEST,
      registrationPart1
    ),
  ]);
}

/*************************************************/
