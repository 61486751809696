import React, { useEffect } from "react";

import * as CoachActions from "redux/coach/coach.actions";
import * as Styles from "./kooperation.styles";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import * as G from "styles/General.Styles";
import FooterNew from "components/FooterNew/FooterNew";
import RegistrationForm from "./RegisterationForm";
import { useDispatch, useSelector } from "react-redux";
import { IS_LOCAL } from "configs/utils";
// intial values for the form
const coach_website_url = IS_LOCAL
  ? "https://ronja-fci49818.foodiary.app"
  : `${window.location.protocol}//${window.location.host}`;
const Kooperation = () => {
  const dispatch = useDispatch();
  const coachData = useSelector((state) => state.coach.coach);
  useEffect(() => {
    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, []);
  return (
    <>
      <Styles.Container>
        <G.ContainerNavbar>
          <StickyNavbar openSurveyModal={() => {}} />
        </G.ContainerNavbar>
        <RegistrationForm />
      </Styles.Container>
      <FooterNew />
    </>
  );
};

export default Kooperation;
