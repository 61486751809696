import backIcon from "assets/images/back-icon.svg";
import { Link, useLocation } from "react-router-dom";

import * as Styles from "./GenderStyles";
import { Checkbox } from "antd";
import MaleIcon from "assets/images/onboarding/Icon-male.svg";
import FemaleIcon from "assets/images/onboarding/Icon-woman.svg";
import { useEffect, useState } from "react";
import {
  getOnboardingStorage,
  setOnboardingStorage,
  GENDER_STEP,
} from "../storage";
import { routerNameToSave } from "helper/routeHelper";
import { useRouteNavigation } from "hooks/useRouteNavigation";

function Gender({ currentPath }) {
  const location = useLocation();
  const { handleNavigate, handleNavigateBack } = useRouteNavigation();

  routerNameToSave(location.state?.params ?? "");

  const [selectedGender, setSelectedGender] = useState(
    getOnboardingStorage(GENDER_STEP)
  );
  const [validationError, setValidationError] = useState(null);

  const options = [
    { title: "Mann", value: "male", icon: MaleIcon },
    { title: "Frau", value: "female", icon: FemaleIcon },
  ];

  useEffect(() => {
    setValidationError(null);
  }, [selectedGender]);

  const handleNextPage = () => {
    if (!selectedGender.value) {
      return setValidationError("Bitte wähle das Geschlecht");
    }

    setOnboardingStorage(GENDER_STEP, selectedGender);
    handleNavigate(currentPath);
  };
  const goBack = () => {
    handleNavigateBack(currentPath);
  };
  return (
    <div>
      <div className="step-header">
        <div className="header-content">
          <Link
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">Wir erstellen deinen Plan.</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">
            Weiter
          </span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className="section-titles-wrapper">
            <div className="primary-title">
              Dein Geschlecht ist die Basis für den Kalorienbedarf.
            </div>
            <div className="accent-subtitle">
              Der Grundumsatz unterscheidet sich bei Mann und Frau.
            </div>
            <div className="small-subtitle">Wähle dein Geschlecht</div>
          </div>
          {validationError ? (
            <div className="error-message">{validationError}</div>
          ) : (
            ""
          )}
          <div className="gender-options">
            {options.map((option) => (
              <Styles.GenderOption
                key={option.value}
                onClick={() => setSelectedGender(option)}
                selected={selectedGender.value === option.value}
              >
                <div className="option-content">
                  <div className="icon">
                    <img src={option.icon} alt="" />
                  </div>
                  <div className="name">{option.title}</div>
                </div>
                <div className="option-checkbox">
                  <Checkbox
                    checked={selectedGender.value === option.value}
                    onChange={() => setSelectedGender(option)}
                  />
                </div>
              </Styles.GenderOption>
            ))}
          </div>
        </Styles.MainContainer>
      </div>
    </div>
  );
}

export default Gender;
