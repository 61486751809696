import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { DatePicker, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import * as Styles from "./kooperation.styles";
import { validationSchema } from "./validationSchema";
import * as CoachActions from "redux/coach/coach.actions";
import { filterName } from "configs/utils";
import { companyRadioOptions, RadioOptions } from "./constant";

const initialValues = {
  vorname: "",
  nachname: "",
  strasse: "",
  hausnummer: "",
  plz: "",
  ort: "",
  email: "",
  firmenname: "",
  steuernummer: "",
  position: "",
  kontaktnummer: "",
  unternehmenswebseite: "",
  iban: "",
  bic: "",
  contract_duration: "",
  agbAccepted: false,
  privacyAccepted: false,
  registrationType: "privatperson", // Add this to track the selection
  geschlecht: "",
  unternehmensart: "",
};

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const { coach, loading } = useSelector((state) => state.coach);
  const [ip, setIP] = useState("");
  const saveValuesRef = useRef();
  const qrcodeUrl = useRef();
  const savePassword = useRef();
  const saveID = useRef();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    })();
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const body = {
      firstname: values.vorname,
      lastname: values.nachname,
      email: values.email,
      company: {
        name: values?.firmenname
          ? values?.firmenname
          : `${values.vorname} ${values.nachname}`,

        description: "Kooperationspartner",
      },
      address: {
        address: `${values.hausnummer} ${values.strasse} , ${values.ort} ${values.plz}`,
        postal_code: values.plz,
        city: values.ort,
        address_two: null,
        state: null,
        country: {
          code: "DE",
        },
      },
      custom_fields: {
        iban: values.iban,
        bic: values.bic,
      },
    };

    dispatch(
      CoachActions.saveKooperationFormRequest({
        body,
        callback: (res) => {
          if (res) {
            if (res instanceof Error) {
              setSubmitting(false);
              const errors = res.response?.data?.body.errors;
              if (errors.length) {
                return alert(`❌ ${errors[0].message}`);
              } else {
                return alert("❌ Something went wrong");
              }
            }
            savePassword.current = res.body.password;
            saveID.current = res.body.id;

            dispatch(
              CoachActions.addAffiliateProgram({
                body: {
                  email: values.email,
                  affiliateId: res?.body?.id,
                  programId: `${filterName(coach.prename)}-${filterName(
                    coach.surname
                  )}-foodiary-coach-${coach.FranchiseID}`,
                },
                callback: (res) => {
                  const coachWebsite = res?.body?.referral_link?.link;

                  if (
                    !coachWebsite ||
                    !coachWebsite.toLowerCase().includes("fci") ||
                    !coachWebsite.toLowerCase().includes("ref=") ||
                    !coachWebsite.toLowerCase().includes("/erstgespraech")
                  ) {
                    return alert(
                      "❌ Dein QR-Code konnte nicht erstellt werden"
                    );
                  }
                  const formData = new FormData();
                  saveValuesRef.current = res.body.referral_link.link;
                  formData.append("qr_url", res.body.referral_link.link);
                  formData.append(
                    "aws_filepath_name",
                    `Marketingportal/QRCode/${coach.FranchiseID}/${values.vorname}-${values.nachname}-${coach.FranchiseID}.png`
                  );
                  dispatch(
                    CoachActions.AdminCoachQRCode({
                      formData,
                      callback: (res) => {
                        qrcodeUrl.current = res.data.path;
                        const body = {
                          file_url: `Marketingportal/Contracts/${coach.FranchiseID}/${values.vorname}-${values.nachname}-${coach.FranchiseID}.pdf`,
                          data: {
                            firmendaten: {
                              firmenname: values.firmenname,
                              straße: values.strasse,
                              hsnr: values.hausnummer,
                              plz: values.plz,
                              ort: values.ort,
                              telefonnummer: values.kontaktnummer,
                              email: values.email,
                              steuernummer_ust_id: values.steuernummer,
                            },
                            gesetzlicher_vertreter: {
                              gesetzlicher_vertreter_name: `${values.vorname} ${values.nachname}`,
                              geschlecht: values.geschlecht,
                              email: values.email,
                              inhaber: values.position === "Inhaber" ? "x" : "",
                              studioleiter:
                                values.position === "Studioleiter" ? "x" : "",
                              geschäftsführer:
                                values.position === "Geschäftsführer"
                                  ? "x"
                                  : "",
                              sonstiges:
                                values.position === "Sonstiges" ? "x" : "",
                            },
                            vorname_nachname_coach: `${coach.prename} ${coach.surname}`,
                            auszahlungskonto: {
                              iban: values.iban,
                              bic: values.bic,
                            },
                            start_datum:
                              values.contract_duration.split(" - ")[0],
                            end_datum: values.contract_duration.split(" - ")[1],
                            ip_adress: ip,
                          },
                        };

                        dispatch(
                          CoachActions.createContract({
                            body,
                            callback: (res) => {
                              const finalUrl = res.data.path.replace(
                                "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/",
                                ""
                              );
                              const formData = new FormData();
                              formData.append(
                                "connectedCoachId",
                                coach.trainerID
                              );
                              formData.append(
                                "companyName",
                                values?.firmenname
                                  ? values?.firmenname
                                  : `${values.vorname} ${values.nachname}`
                              );
                              formData.append("street", values.strasse);
                              formData.append("zipcode", values.plz);
                              formData.append("houseNumber", values.hausnummer);
                              formData.append("city", values.ort);
                              formData.append(
                                "contact",
                                `${values.vorname} ${values.nachname}`
                              );
                              formData.append("email", values.email);
                              formData.append("phone", values.kontaktnummer);
                              formData.append(
                                "website",
                                values?.unternehmenswebseite
                                  ? values?.unternehmenswebseite
                                  : "Keine Angabe"
                              );
                              formData.append("IBAN", values.iban);
                              formData.append("BIC", values.bic);
                              formData.append("status", "Partnerschaft aktiv");
                              formData.append("contractUrl", finalUrl);
                              formData.append("companylogo", null);
                              formData.append(
                                "partnerUrl",
                                saveValuesRef.current
                              );
                              formData.append("qrCodeUrl", qrcodeUrl.current);
                              formData.append(
                                "Steuernummer",
                                values.steuernummer
                              );
                              formData.append("TapfiliateID", saveID.current);
                              formData.append(
                                "Unternehmensart",
                                values?.unternehmensart
                                  ? values.unternehmensart
                                  : "Privatperson"
                              );

                              const qrCodeUrl = qrcodeUrl.current;
                              dispatch(
                                CoachActions.createPartner({
                                  formData,
                                  callback: (res) => {
                                    const body = {
                                      template_name:
                                        "neuer-foodiary-kooperationspartner-zugangsdaten-tapfiliate",

                                      template_content: [
                                        {
                                          name: "prename",
                                          content: values.vorname,
                                        },
                                        {
                                          name: "email",
                                          content: values.email,
                                        },
                                        {
                                          name: "password",
                                          content: savePassword.current,
                                        },
                                      ],
                                      subject: "Zugangsdaten Partnerportal.",
                                      from_email: "backoffice@foodiary.app",
                                      to: [
                                        {
                                          email: values.email,
                                          type: "to",
                                        },
                                        {
                                          email: "mergenthalerfelix@gmail.com",
                                          type: "bcc",
                                        },
                                      ],
                                    };
                                    dispatch(
                                      CoachActions.sendTransactionalEmail({
                                        body,
                                        callback: (res) => {
                                          setSubmitting(false);
                                          history.push({
                                            pathname:
                                              "/kooperation/erfolgreich",
                                            state: {
                                              qrCodeUrl: qrCodeUrl,
                                              partnerUrl: saveValuesRef.current,
                                            },
                                          });
                                        },
                                      })
                                    );
                                  },
                                })
                              );
                            },
                          })
                        );
                      },
                    })
                  );
                },
              })
            );
          }
        },
      })
    );
    //we need to run this api after success => https://app.foodiary.app/api/add-affiliate-program
    // {“affiliateId":“felixmergenthaler9",“programId”:“sebastian-mayer-foodiary-coach-fci1"}
    //
  };
  const handleRadioChange = (e, setFieldValue) => {
    const isPrivate = e.target.value === "privatperson";
    setFieldValue(
      "registrationType",
      isPrivate ? "privatperson" : "unternehmen"
    );
  };
  const handleCompanyRadioChange = (e, setFieldValue) => {
    const genderType = e.target.value === "mann";
    setFieldValue("geschlecht", genderType ? "mann" : "frau");
  };
  return (
    <>
      <Styles.HeaderContent>
        <Styles.Chip className="font-medium">REGISTRIEREN</Styles.Chip>
        <Styles.HeaderTitle className="ct-text-block font-bold color-dark text-center text-5xl">
          Registrierung als
          <br />
          Kooperationspartner
        </Styles.HeaderTitle>
        <Styles.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
          Von einer attraktiven Provision in Höhe von 13,3 % bei der
          <br />
          Weiterempfehlung meines Ernährungscoachings profitieren.
        </Styles.HeaderDescription>
      </Styles.HeaderContent>
      <Styles.SubContainer>
        <Styles.FormContainer>
          <Styles.HeaderTitle className="ct-text-block font-bold color-dark text-3xl">
            Registrierung abschließen
          </Styles.HeaderTitle>
          <Styles.HeaderDescription className="ct-text-block color-paragraph text-base mb-10 ">
            Wir legen die Partnerschaft an und richten direkt die
            Provisionszahlungen ein.
          </Styles.HeaderDescription>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleChange, values, setFieldValue }) => (
              <Form>
                <Styles.Row className="max-width260">
                  <Styles.RadioInputContainer>
                    <Styles.RadioInput
                      type="radio"
                      name="registrationType"
                      value="privatperson"
                      checked={values.registrationType === "privatperson"}
                      onChange={(e) => handleRadioChange(e, setFieldValue)}
                    />
                    <Styles.RadioLabel className="text-base ">
                      Privatperson
                    </Styles.RadioLabel>
                  </Styles.RadioInputContainer>
                  <Styles.RadioInputContainer>
                    <Styles.RadioInput
                      type="radio"
                      name="registrationType"
                      value="unternehmen"
                      checked={values.registrationType === "unternehmen"}
                      onChange={(e) => handleRadioChange(e, setFieldValue)}
                    />
                    <Styles.RadioLabel className="text-base">
                      Unternehmen
                    </Styles.RadioLabel>
                  </Styles.RadioInputContainer>
                </Styles.Row>
                <Styles.FieldLabel className="mt-4">
                  Geschlecht*
                </Styles.FieldLabel>
                <Styles.Row className="mb-4 max-width200">
                  <Styles.RadioInputContainer>
                    <Styles.RadioInput
                      type="radio"
                      name="geschlecht"
                      value="mann"
                      checked={values.geschlecht === "mann"}
                      onChange={(e) =>
                        handleCompanyRadioChange(e, setFieldValue)
                      }
                    />
                    <Styles.RadioLabel className="text-base">
                      Mann
                    </Styles.RadioLabel>
                  </Styles.RadioInputContainer>
                  <Styles.RadioInputContainer>
                    <Styles.RadioInput
                      type="radio"
                      name="geschlecht"
                      value="frau"
                      checked={values.geschlecht === "frau"}
                      onChange={(e) =>
                        handleCompanyRadioChange(e, setFieldValue)
                      }
                    />
                    <Styles.RadioLabel className="text-base">
                      Frau
                    </Styles.RadioLabel>
                  </Styles.RadioInputContainer>
                </Styles.Row>
                <ErrorMessage
                  name="geschlecht"
                  component={Styles.ErrorText}
                  style={{ position: "relative", top: -26 }}
                />
                <Styles.FormSection>
                  <Styles.FieldLabel>ANSPRECHPARTNER*</Styles.FieldLabel>
                  <Styles.FieldRow>
                    <Styles.FieldContainer>
                      <Styles.InputField
                        type="text"
                        name="vorname"
                        placeholder="Vorname"
                        onChange={handleChange}
                        value={values.vorname}
                      />
                      <ErrorMessage
                        name="vorname"
                        component={Styles.ErrorText}
                      />
                    </Styles.FieldContainer>
                    <Styles.FieldContainer>
                      <Styles.InputField
                        type="text"
                        name="nachname"
                        placeholder="Nachname"
                        onChange={handleChange}
                        value={values.nachname}
                      />
                      <ErrorMessage
                        name="nachname"
                        component={Styles.ErrorText}
                      />
                    </Styles.FieldContainer>
                  </Styles.FieldRow>
                </Styles.FormSection>
                <Styles.FieldRow>
                  <Styles.FieldContainer>
                    <Styles.FieldLabel>Straße*</Styles.FieldLabel>
                    <Styles.InputField
                      type="text"
                      name="strasse"
                      placeholder="Talstraße"
                      onChange={handleChange}
                      value={values.strasse}
                    />
                    <ErrorMessage name="strasse" component={Styles.ErrorText} />
                  </Styles.FieldContainer>
                  <Styles.FieldContainer>
                    <Styles.FieldLabel>HAUSNUMMER*</Styles.FieldLabel>
                    <Styles.DatePickerWrapper>
                      <Styles.InputField
                        type="text"
                        name="hausnummer"
                        placeholder="9"
                        onChange={handleChange}
                        value={values.hausnummer}
                      />
                    </Styles.DatePickerWrapper>
                    <ErrorMessage
                      name="hausnummer"
                      component={Styles.ErrorText}
                    />
                  </Styles.FieldContainer>
                </Styles.FieldRow>
                <Styles.FieldRow>
                  <Styles.FieldContainer>
                    <Styles.FieldLabel>PLZ*</Styles.FieldLabel>
                    <Styles.InputField
                      type="text"
                      name="plz"
                      placeholder="71397"
                      onChange={handleChange}
                      value={values.plz}
                    />
                    <ErrorMessage name="plz" component={Styles.ErrorText} />
                  </Styles.FieldContainer>
                  <Styles.FieldContainer>
                    <Styles.FieldLabel>ORT*</Styles.FieldLabel>
                    <Styles.InputField
                      type="text"
                      name="ort"
                      placeholder="Leutenbach"
                      onChange={handleChange}
                      value={values.ort}
                    />
                    <ErrorMessage name="ort" component={Styles.ErrorText} />
                  </Styles.FieldContainer>
                </Styles.FieldRow>

                <Styles.FieldRow>
                  <Styles.FieldContainer>
                    <Styles.FieldLabel>E-MAIL ADRESSE*</Styles.FieldLabel>
                    <Styles.InputField
                      type="email"
                      name="email"
                      placeholder="office@foodiary.app"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <ErrorMessage name="email" component={Styles.ErrorText} />
                  </Styles.FieldContainer>
                  <Styles.FieldContainer>
                    <Styles.FieldLabel>KONTAKTNUMMER*</Styles.FieldLabel>

                    <Styles.StyledPhoneInputWrapper>
                      <PhoneInput
                        international
                        defaultCountry="DE"
                        value={values.kontaktnummer}
                        onChange={(phone) =>
                          setFieldValue("kontaktnummer", phone)
                        }
                        placeholder="+49 1741953597"
                      />
                    </Styles.StyledPhoneInputWrapper>
                    <ErrorMessage
                      name="kontaktnummer"
                      component={Styles.ErrorText}
                    />
                  </Styles.FieldContainer>
                </Styles.FieldRow>
                {values.registrationType === "unternehmen" && (
                  <React.Fragment>
                    <Styles.FormSection>
                      <Styles.FieldLabel>FIRMENNAME*</Styles.FieldLabel>
                      <Styles.InputField
                        type="text"
                        name="firmenname"
                        placeholder="Foodiary GmbH"
                        onChange={handleChange}
                        value={values.firmenname}
                      />
                      <ErrorMessage
                        name="firmenname"
                        component={Styles.ErrorText}
                      />
                    </Styles.FormSection>
                    <Styles.FormSection>
                      <Styles.FieldLabel>
                        STEUERNUMMER / UST-ID*
                      </Styles.FieldLabel>
                      <Styles.InputField
                        type="text"
                        name="steuernummer"
                        placeholder="90/123/1234"
                        onChange={handleChange}
                        value={values.steuernummer}
                      />
                      <ErrorMessage
                        name="steuernummer"
                        component={Styles.ErrorText}
                      />
                    </Styles.FormSection>

                    <Styles.FormSection>
                      <Styles.FieldLabel>
                        UNTERNEHMENSWEBSEITE*
                      </Styles.FieldLabel>
                      <Styles.InputField
                        type="text"
                        name="unternehmenswebseite"
                        placeholder="https://www.foodiary.app/"
                        onChange={handleChange}
                        value={values.unternehmenswebseite}
                      />
                      <ErrorMessage
                        name="unternehmenswebseite"
                        component={Styles.ErrorText}
                      />
                    </Styles.FormSection>
                    <Styles.FormSection>
                      <Styles.FieldLabel>
                        DIE EINRICHTUNG WIRD VERTRETEN DURCH:*
                      </Styles.FieldLabel>

                      <Styles.RadioGrid>
                        {RadioOptions.map((option, index) => (
                          <Styles.RadioInputContainer key={index}>
                            <Styles.RadioInput
                              type="radio"
                              value={option.value}
                              checked={values.position === option.value}
                              onChange={() =>
                                setFieldValue("position", option.value)
                              }
                            />
                            <Styles.RadioLabel>
                              {option.label}
                            </Styles.RadioLabel>
                          </Styles.RadioInputContainer>
                        ))}
                      </Styles.RadioGrid>

                      <ErrorMessage
                        name="position"
                        component={Styles.ErrorText}
                      />
                    </Styles.FormSection>
                    <Styles.FormSection>
                      <Styles.FieldLabel>Unternehmensart:*</Styles.FieldLabel>

                      <Styles.CompanyRadioGrid>
                        {companyRadioOptions.map((option, index) => (
                          <Styles.RadioInputContainer key={index}>
                            <Styles.RadioInput
                              type="radio"
                              value={option.value}
                              checked={values.unternehmensart === option.value}
                              onChange={() =>
                                setFieldValue("unternehmensart", option.value)
                              }
                            />
                            <Styles.RadioLabel>
                              {option.label}
                            </Styles.RadioLabel>
                          </Styles.RadioInputContainer>
                        ))}
                      </Styles.CompanyRadioGrid>

                      <ErrorMessage
                        name="position"
                        component={Styles.ErrorText}
                      />
                    </Styles.FormSection>
                  </React.Fragment>
                )}
                <Styles.FormSection>
                  <Styles.FieldLabel>AUSZAHLUNGSKONTO*</Styles.FieldLabel>
                  <Styles.FieldContainer style={{ marginBottom: "15px" }}>
                    <Styles.InputField
                      type="text"
                      name="iban"
                      placeholder="IBAN"
                      onChange={handleChange}
                      value={values.iban}
                    />
                    <ErrorMessage name="iban" component={Styles.ErrorText} />
                  </Styles.FieldContainer>
                  <Styles.FieldContainer>
                    <Styles.InputField
                      type="text"
                      name="bic"
                      placeholder="BIC"
                      className="mt-4"
                      onChange={handleChange}
                      value={values.bic}
                    />
                    <ErrorMessage name="bic" component={Styles.ErrorText} />
                  </Styles.FieldContainer>
                  <Styles.FormSection className="mt-10">
                    <Styles.FieldLabel>VERTRAGSLAUFZEIT*</Styles.FieldLabel>
                    <Styles.CalenderPickerWrapper>
                      <DatePicker.RangePicker
                        name="contract_duration"
                        format="DD.MM.YYYY"
                        placeholder={["01.01.2025", "01.01.2026"]}
                        value={
                          values.contract_duration
                            ? [
                                dayjs(
                                  values.contract_duration.split(" - ")[0],
                                  "DD.MM.YYYY"
                                ),
                                dayjs(
                                  values.contract_duration.split(" - ")[1],
                                  "DD.MM.YYYY"
                                ),
                              ]
                            : null
                        }
                        onChange={(dates, dateStrings) => {
                          if (dates) {
                            // Check if end date is before start date
                            const startDate = dayjs(
                              dateStrings[0],
                              "DD.MM.YYYY"
                            );
                            const endDate = dayjs(dateStrings[1], "DD.MM.YYYY");

                            if (endDate.isBefore(startDate)) {
                              alert(
                                "Das Enddatum kann nicht vor dem Startdatum liegen"
                              );
                              return;
                            }

                            setFieldValue(
                              "contract_duration",
                              `${dateStrings[0]} - ${dateStrings[1]}`
                            );
                          } else {
                            setFieldValue("contract_duration", "");
                          }
                        }}
                        style={{
                          width: "100%",
                          height: "43px",
                          backgroundColor: "#f9f9f9",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      />
                    </Styles.CalenderPickerWrapper>
                    <ErrorMessage
                      name="contract_duration"
                      component={Styles.ErrorText}
                    />
                  </Styles.FormSection>
                </Styles.FormSection>
                <Styles.TextContainer>
                  <Styles.LegalText>
                    <h3 className="section-title">1. Definitionen</h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>1.1. Foodiary:</strong> Die Foodiary GmbH mit
                        Sitz in der Talstraße 09, 71397 Leutenbach, ist der
                        Anbieter der Produkte "Coaching PRO" und "Coaching
                        PLUS".
                      </div>
                      <div className="subsection">
                        <strong>1.2. Partner:</strong> Jeder
                        Kooperationspartner, der aktiv Kunden für die Produkte
                        von Foodiary vermittelt.
                      </div>
                      <div className="subsection">
                        <strong>1.3. Provision:</strong> Die Vergütung, die der
                        Partner für jede erfolgreiche Kundenvermittlung erhält.
                      </div>
                      <div className="subsection">
                        <strong>1.4. Coaching PRO/PLUS:</strong> Die von
                        Foodiary angebotenen Coaching-Produkte, die im Rahmen
                        dieser Vereinbarung beworben werden.
                      </div>
                    </div>

                    <h3 className="section-title">2. Vertragsgegenstand</h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>2.1.</strong> Diese Allgemeinen
                        Geschäftsbedingungen (AGB) regeln die Bedingungen der
                        Provisionsvereinbarung zwischen Foodiary und dem
                        Partner.
                      </div>
                      <div className="subsection">
                        <strong>2.2.</strong> Der Gegenstand dieser Vereinbarung
                        ist die aktive Vermittlung von Kunden durch den Partner
                        für die Produkte "Coaching PRO" und "Coaching PLUS".
                      </div>
                    </div>

                    <h3 className="section-title">
                      3. Vermittlungsmodalitäten & Nachweis der Vermittlung
                    </h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>3.1.</strong> Eine Vermittlung gilt als
                        erfolgreich, wenn der vermittelte Kunde eines der
                        Produkte "Coaching PRO" oder "Coaching PLUS" über einen
                        Tracking-Link, einen individuellen Code oder durch eine
                        manuelle Bestätigung von Foodiary erwirbt.
                      </div>
                      <div className="subsection">
                        <strong>3.2.</strong> Im Falle von Unstimmigkeiten
                        obliegt es dem Partner, den Nachweis der erfolgreichen
                        Vermittlung zu erbringen, indem er entsprechende
                        Dokumentationen, wie Tracking-Links oder Bestätigungen,
                        vorlegt.
                      </div>
                    </div>

                    <h3 className="section-title">
                      4. Provisionsanpassung und Änderungen der Vergütung
                    </h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>4.1.</strong> Foodiary behält sich das Recht
                        vor, die Provisionssätze einseitig zu ändern. Änderungen
                        werden dem Partner mindestens 30 Tage im Voraus
                        schriftlich mitgeteilt.
                      </div>
                    </div>

                    <h3 className="section-title">
                      5. Abrechnung und Auszahlung
                    </h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>5.1.</strong> Die Abrechnung der Provisionen
                        erfolgt monatlich zum 15. des Folgemonats.
                      </div>
                      <div className="subsection">
                        <strong>5.2.</strong> Foodiary erstellt für den
                        Abrechnungszeitraum eine Gutschrift.
                      </div>
                      <div className="subsection">
                        <strong>5.3.</strong> Die Auszahlung der Provision an
                        den Partner erfolgt, sobald der vermittelte Kunde die
                        vollständige Zahlung an Foodiary geleistet hat oder die
                        erste Rate, falls der Kunde eine Ratenzahlung wählt.
                      </div>
                      <div className="subsection">
                        <strong>5.4.</strong> Der Provisionsbetrag ist brutto
                        und unterliegt der Einkommensteuerpflicht des Partners.
                        Der Partner ist verantwortlich für die ordnungsgemäße
                        Versteuerung der erhaltenen Beträge.
                      </div>
                      <div className="subsection">
                        <strong>5.5.</strong> Der Partner erhält Zugang zum
                        Affiliate-Programm von Foodiary, das ihm Einblick in
                        offene Provisionsauszahlungen, Werbeklicks und
                        abgeschlossene Verkäufe gewährt.
                      </div>
                    </div>

                    <h3 className="section-title">
                      6. Vertragslaufzeit und Kündigung
                    </h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>6.1.</strong> Sofern keine feste
                        Vertragslaufzeit vereinbart wurde, kann diese
                        Vereinbarung von beiden Parteien jederzeit ohne Angabe
                        von Gründen gekündigt werden.
                      </div>
                      <div className="subsection">
                        <strong>6.2.</strong> Offene Provisionszahlungen werden
                        bis zum Beendigungsdatum anteilig berechnet und im
                        nächsten Abrechnungsintervall ausgezahlt.
                      </div>
                    </div>

                    <h3 className="section-title">7. Pflichten des Partners</h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>7.1.</strong> Der Partner verpflichtet sich,
                        alle Werbemaßnahmen im Einklang mit den geltenden
                        Gesetzen und den Richtlinien von Foodiary durchzuführen.
                      </div>
                      <div className="subsection">
                        <strong>7.2.</strong> Unlautere Werbemaßnahmen oder
                        irreführende Aussagen über Foodiary sind strikt
                        untersagt.
                      </div>
                      <div className="subsection">
                        <strong>7.3.</strong> Der Partner verpflichtet sich, die
                        Interessen von Foodiary zu wahren und eine positive
                        Markenwahrnehmung zu fördern.
                      </div>
                    </div>

                    <h3 className="section-title">8. Haftungsausschluss</h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>8.1.</strong> Foodiary haftet nicht für
                        entgangene Gewinne oder sonstige Schäden, die aus der
                        Teilnahme am Provisionsprogramm resultieren, es sei
                        denn, diese beruhen auf grober Fahrlässigkeit oder
                        Vorsatz seitens Foodiary.
                      </div>
                    </div>

                    <h3 className="section-title">9. Datenschutz</h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>9.1.</strong> Der Partner verpflichtet sich,
                        personenbezogene Daten vertraulich zu behandeln und
                        ausschließlich im Rahmen dieser Vereinbarung zu
                        verwenden.
                      </div>
                      <div className="subsection">
                        <strong>9.2.</strong> Es gelten die
                        Datenschutzbestimmungen von Foodiary, die auf der
                        Website von Foodiary einsehbar sind.
                      </div>
                    </div>

                    <h3 className="section-title">10. Schlussbestimmungen</h3>
                    <div className="section-content">
                      <div className="subsection">
                        <strong>10.1.</strong> Sollten einzelne Bestimmungen
                        dieser AGB unwirksam sein oder werden, bleibt die
                        Wirksamkeit der übrigen Bestimmungen unberührt.
                      </div>
                      <div className="subsection">
                        <strong>10.2.</strong> Änderungen oder Ergänzungen
                        dieser AGB bedürfen der Schriftform.
                      </div>
                      <div className="subsection">
                        <strong>10.3.</strong> Gerichtsstand für alle
                        Streitigkeiten aus oder im Zusammenhang mit dieser
                        Vereinbarung ist der Sitz von Foodiary.
                      </div>
                    </div>
                  </Styles.LegalText>
                </Styles.TextContainer>
                <Styles.ConsentContainer>
                  <Styles.CheckboxContainer>
                    <Checkbox
                      name="agbAccepted"
                      checked={values.agbAccepted}
                      onChange={handleChange}
                    />
                    <Styles.CheckboxLabel
                      htmlFor="agbAccepted"
                      className="text-base"
                    >
                      Ich akzeptiere die{" "}
                      <Styles.LinkText
                        href="https://foodiary.app/terms/werbepartner/"
                        target="_blank"
                      >
                        AGB
                      </Styles.LinkText>{" "}
                      von Foodiary
                    </Styles.CheckboxLabel>
                  </Styles.CheckboxContainer>
                  <ErrorMessage
                    name="agbAccepted"
                    component={Styles.ErrorText}
                  />

                  <Styles.CheckboxContainer className="mt-4">
                    <Checkbox
                      name="privacyAccepted"
                      checked={values.privacyAccepted}
                      onChange={handleChange}
                      className="text-base"
                    />
                    <Styles.CheckboxLabel htmlFor="privacyAccepted">
                      Ich stimme der{" "}
                      <Styles.LinkText
                        href="https://foodiary.app/terms/datenschutz/"
                        target="_blank"
                      >
                        Datenschutzerklärung
                      </Styles.LinkText>{" "}
                      sowie der Verarbeitung meiner Daten zu.
                    </Styles.CheckboxLabel>
                  </Styles.CheckboxContainer>
                  <ErrorMessage
                    name="privacyAccepted"
                    component={Styles.ErrorText}
                  />
                </Styles.ConsentContainer>

                <Styles.ButtonWrapper
                  type="submit"
                  className="btn-dark w-2by6 m-auto mt-4 "
                  // disabled={isSubmitting}
                >
                  {isSubmitting
                    ? "Partnerschaft starten..."
                    : "Partnerschaft starten"}
                </Styles.ButtonWrapper>
              </Form>
            )}
          </Formik>
        </Styles.FormContainer>
      </Styles.SubContainer>
    </>
  );
};

export default RegistrationForm;
