import { useLocation } from "react-router-dom";

import { routerNameToSave } from "helper/routeHelper";
import { useRouteNavigation } from "hooks/useRouteNavigation";
import * as Styles from "./HealthAnalyse.styles";
import icon1 from "assets/images/svg/health-analyse/number_1.svg";
import icon2 from "assets/images/svg/health-analyse/number_2.svg";
import icon3 from "assets/images/svg/health-analyse/number_3.svg";

function HealthAnalyse({ currentPath }) {
  const location = useLocation();
  const { handleNavigate, handleNavigateBack } = useRouteNavigation();

  routerNameToSave(location.state?.params ?? "");

  const handleNextPage = () => handleNavigate(currentPath);

  return (
    <div>
      <div className="step-header">
        <div className="header-content">
          <div>
            <div className="headline">Vorstellung</div>
            <div className="sub-headline">3 Teile des Erstgespräch.</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">
            Weiter
          </span>
        </div>
      </div>
      <Styles.MainContainer>
        <div className="step-content">
          <div className="section-titles-wrapper gap">
            <div className="primary-title">So unterstütze ich dich.</div>
            <div className="subtitle">
              In den kommenden 3 Teilen des Erstgespräches findet die Anamnese,
              Auswertung und Vorstellung statt.
            </div>
          </div>
        </div>
        <Styles.CardContainer>
          <Card
            icon={icon1}
            title={"Erstellen deines Ernährungsplanes"}
            description={
              "Wir evaluieren deinen IST-Zustand und erstellen auf Grundlage deines Zieles, den täglichen Gewohnheiten und Wünschen deinen Ernährungsplan."
            }
          />
          <Card
            icon={icon2}
            title={"Vorstellen deines Ernährungsplanes"}
            description={
              "Ich werde dir deinen Ernährungsplan erklären. Hierbei gehe ich auf deinen Kalorienbedarf, die Ernährungsform und die Rezeptauswahl ein."
            }
          />
          <Card
            icon={icon3}
            title={"Start deiner Ernährungsumstellung"}
            description={
              "Du erhältst wichtige Informationen, wie du deinen Ernährungsplan gesund umsetzen kannst und welche Tipps & Tricks dir dabei helfen."
            }
          />
        </Styles.CardContainer>
      </Styles.MainContainer>
    </div>
  );
}

export default HealthAnalyse;

function Card({ title, description, icon }) {
  return (
    <div className="column">
      <div className="card-icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="card-content">
        <div className="card-title px-2">{title}</div>
        <div className="card-description">{description}</div>
      </div>
    </div>
  );
}
