import CoachActionTypes from "redux/coach/coach.types";

// Trainer ID
export const trainerIdRequest = (payload) => ({
  type: CoachActionTypes.TRAINER_ID_REQUEST,
  payload,
});

export const trainerIdSuccess = (payload) => ({
  type: CoachActionTypes.TRAINER_ID_SUCCESS,
  payload,
});

export const trainerIdFailure = (error) => ({
  type: CoachActionTypes.TRAINER_ID_FAILURE,
  payload: error,
});

// Coach Data
export const coachDataRequest = (payload) => ({
  type: CoachActionTypes.COACH_DATA_REQUEST,
  payload,
});

export const coachDataSuccess = (payload) => ({
  type: CoachActionTypes.COACH_DATA_SUCCESS,
  payload,
});

export const coachDataFailure = (error) => ({
  type: CoachActionTypes.COACH_DATA_FAILURE,
  payload: error,
});

// Loading
export const loadingStart = () => ({
  type: CoachActionTypes.LOADING_START,
});

export const loadingStop = () => ({
  type: CoachActionTypes.LOADING_STOP,
});

// Trainer Leads
export const saveTrainerLeadRequest = (payload) => ({
  type: CoachActionTypes.TRAINER_LEADS_REQUEST,
  payload,
});

export const saveTrainerLeadSuccess = (payload) => ({
  type: CoachActionTypes.TRAINER_LEADS_SUCCESS,
  payload,
});

export const saveTrainerLeadFailure = (error) => ({
  type: CoachActionTypes.TRAINER_LEADS_FAILURE,
  payload: error,
});

// Loading
export const saveTrainerLeadLoadingStart = () => ({
  type: CoachActionTypes.TRAINER_LEADS_LOADING_START,
});

export const saveTrainerLeadLoadingStop = () => ({
  type: CoachActionTypes.TRAINER_LEADS_LOADING_STOP,
});

export const saveKooperationFormRequest = (payload) => ({
  type: CoachActionTypes.KOOPERATION_FORM_REQUEST,
  payload,
});
export const saveKooperationFormSuccess = (payload) => ({
  type: CoachActionTypes.KOOPERATION_FORM_SUCCESS,
  payload,
});
export const saveKooperationFormFailure = (error) => ({
  type: CoachActionTypes.KOOPERATION_FORM_FAILURE,
  payload: error,
});
export const addAffiliateProgram = (payload) => ({
  type: CoachActionTypes.ADD_AFFILIATE_PROGRAM,
  payload,
});
export const AdminCoachQRCode = (payload) => ({
  type: CoachActionTypes.ADMIN_COACH_QR_CODE,
  payload,
});
export const createPartner = (payload) => ({
  type: CoachActionTypes.CREATE_PARTNER,
  payload,
});
export const createContract = (payload) => ({
  type: CoachActionTypes.CREATE_CONTRACT,
  payload,
});
export const sendTransactionalEmail = (payload) => ({
  type: CoachActionTypes.SEND_TRANSACTIONAL_EMAIL,
  payload,
});
