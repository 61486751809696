import styled from "styled-components";
import { Field } from "formik";

export const Container = styled.div`
  background: transparent linear-gradient(90deg, #eeeeee 0%, #f8f8f8 100%) 0% 0%
    no-repeat padding-box;
  position: relative;
`;
export const SubContainer = styled.div`
  width: 100%;
  background: white;
  position: relative;
  .max-width200 {
    max-width: 200px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .max-width260 {
    max-width: 260px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
`;
export const FormContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 7rem 10rem;
  background-color: white;
  font-family: Arial, sans-serif;
  position: relative;
  top: -100px;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

  /* For better responsiveness */
  @media (max-width: 992px) {
    top: -50px;

    width: 90%;
    padding: 3rem 3rem 6rem 3rem;

    max-width: none;
  }
`;
export const SelectField = styled(Field)`
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  background-color: #f9f9f9;
  font-size: 16px;
  appearance: none; /* Remove default arrow */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23A5B5AF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center; /* Adjust this value to move icon more to the left */
  &:invalid {
    color: #a5b5af; /* Gray color for placeholder */
  }

  option {
    color: #142c1f; /* Normal text color for actual options */
  }

  &:focus {
    outline: none;
    border-color: #c0c0c0;
  }
`;

export const FormSection = styled.div`
  margin-bottom: 30px;
`;

export const FieldLabel = styled.label`
  display: block;
  font-size: 14px;
  color: #44554c;
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.42px;
`;

export const InputField = styled(Field)`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px;
  // placeholder color
  ::placeholder {
    color: #a5b5af;
  }
  &:focus {
    outline: none;
    border: 1px solid #c0c0c0;
  }
`;
export const SimpleInputField = styled.input`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px;
  // placeholder color
  ::placeholder {
    color: #a5b5af;
  }
  &:focus {
    outline: none;
    border: 1px solid #c0c0c0;
  }
`;

export const ErrorText = styled.div`
  color: #d32f2f;
  margin-top: 4px;
`;

export const FieldRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FieldContainer = styled.div`
  position: relative;
  flex: 1;
`;

export const ButtonWrapper = styled.button`
  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
export const TextContainer = styled.div`
  height: 300px;
  overflow-y: auto;
`;
export const LegalText = styled.div`
  color: #44554c;
  padding: 5px 40px;
  background-color: #f5f5f5;
  border-radius: 4px;
  p {
    color: #44554c;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: #142c1f;
    margin: 24px 0 16px;
  }

  strong {
    color: #142c1f;
    font-weight: 600;
  }

  .section-title {
    font-weight: 600;
    color: #142c1f;
    margin-bottom: 14px;
    margin-top: 26px;
  }

  .section-content {
    margin-bottom: 24px;
  }

  .subsection {
    margin-bottom: 12px;
  }

  @media screen and (max-width: 992px) {
    padding: 1px 20px;
  }
`;
export const ConsentContainer = styled.div`
  background-color: #f9f9f9;
  padding: 30px;
  margin: 30px 0 60px;
  border-radius: 4px;
  @media screen and (max-width: 992px) {
    padding: 30px 20px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  gap: 10px;
`;

export const CheckboxLabel = styled.label`
  color: #44554c;
  cursor: pointer;
`;

export const LinkText = styled.a`
  color: #2dcd95;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;
  text-align: center;
  margin-inline: auto;
  padding-top: calc(170px + 7.4rem);
  padding-bottom: 130px;
  position: relative;
  @media (max-width: 992px) {
    padding-top: 150px;
    padding-bottom: 7rem;
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export const Chip = styled.div`
  border-radius: 42px;
  padding: 12px 24px;
  letter-spacing: 0.32px;
  color: #1c9d87;
  text-transform: uppercase;
  background-color: white;
  width: fit-content;
  /* font-weight: bold; */
`;

export const HeaderTitle = styled.div`
  /* font-size: 3rem;
  line-height: 4rem;
  font-weight: 500; */
  color: #142c1f;
  margin: 20px 0;
`;

export const HeaderDescription = styled.div`
  /* font-size: 1.1rem;
  line-height: 1.8rem;
  font-weight: 400; */
  color: #44554c;
  br {
    display: block;
  }

  /* Hide on mobile */
  @media screen and (max-width: 767px) {
    br {
      display: none;
    }
  }
`;
export const HeaderInner = styled.div`
  padding-top: calc(170px + 7.4rem);
  padding-bottom: 100px;
  position: relative;
  @media (max-width: 992px) {
    padding-top: 150px;
    padding-bottom: 7rem;
  }
`;

export const HeaderContentWrapper = styled.div`
  position: relative;
`;
// Add this to your kooperation.styles.js file
export const DatePickerWrapper = styled.div`
  .ant-picker {
    height: 43px;
    width: 100%;
    border: none;
    background-color: #f9f9f9;
    border-radius: 4px;
  }

  .ant-picker-input > input {
    font-size: 16px !important;
    color: #142c1f;

    &::placeholder {
      color: #a5b5af;
    }
  }

  // Override any other styles like arrows, calendar icon, etc.
  .ant-picker-suffix {
    color: #a5b5af;
  }
`;
export const StyledPhoneInputWrapper = styled.div`
  .PhoneInput {
    width: 100%;
    height: 43px;
    background-color: #f9f9f9;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .PhoneInputCountry {
      margin-right: 10px;
      margin-left: 10px;
    }

    .PhoneInputInput {
      flex: 1;
      border: none;
      background-color: transparent;
      font-size: 16px;
      padding: 12px 0;
      outline: none;

      &::placeholder {
        color: #a5b5af;
      }
    }
  }
`;
export const RadioInputContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const RadioLabel = styled.label`
  color: #44554c;
  margin-top: 5px;
`;
export const RadioInput = styled.input.attrs({ type: "radio" })`
  appearance: none;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 2px solid #ddd;
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    border-color: #2dcd95;

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #2dcd95;
      display: block;
      margin: 2px auto;
    }
  }
`;
export const RadioGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;
export const CopyButton = styled.button`
  border: none;
  cursor: pointer;
  position: absolute;
  /* top: 37px; */
  bottom: 10px;
  right: 20px;
  background: none;
`;

export const DateRangePickerWrapper = styled.div`
  .ant-picker {
    width: 100%;
    height: 43px;
    background-color: #f9f9f9;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }

  .ant-picker-input > input {
    color: #142c1f;
    font-size: 16px;

    &::placeholder {
      color: #a5b5af;
    }
  }

  .ant-picker-range-separator {
    color: #44554c;
  }

  .ant-picker-suffix {
    color: #44554c;
  }
`;
export const CalenderPickerWrapper = styled.div`
  @media screen and (max-width: 992px) {
    .ant-picker-panels {
      flex-direction: column !important;
    }
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const CompanyRadioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
