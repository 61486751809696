// Import the same component files
import _Start from "pages/onboarding/start/Start";
import _Gender from "pages/onboarding/gender/Gender";
import _Goal from "pages/onboarding/goal/Goal";
import _BodyDetails from "pages/onboarding/body-details/BodyDetails";
import _DailyRoutine from "pages/onboarding/daily-routine/DailyRoutine";
import _ActivityLevel from "pages/onboarding/activity-level/ActivityLevel";
import _TrainingDays from "pages/onboarding/training-days/TrainingDays";
import _MealCategories from "pages/onboarding/preferred-meals/MealCategories";
import _Meal from "pages/onboarding/preferred-meals/Meal";
import _TermsInfo from "pages/onboarding/terms-info/TermsInfo";
import _Payment from "pages/onboarding/payment/Payment";
import Welcome from "pages/welcome/Welcome";
import HealthAnalyse from "pages/HealthAnalyse/HealthAnalyse";

// Create a new dynamic route for meals, but under health_analyse
const healthAnalyseMealsRoute = "/health-analyse/preferred-meals/:mealId";

// Define all routes with health_analyse prefix
const HEALTH_ANALYSE_ROUTES = [
  { path: "/health-analyse", component: HealthAnalyse, progressStep: 0 },
  { path: "/health-analyse/start", component: _Start, progressStep: 0 },
  { path: "/health-analyse/gender", component: _Gender, progressStep: 0 },
  { path: "/health-analyse/goal", component: _Goal, progressStep: 0 },
  {
    path: "/health-analyse/body-details",
    component: _BodyDetails,
    progressStep: 0,
  },
  {
    path: "/health-analyse/daily-routine",
    component: _DailyRoutine,
    progressStep: 0,
  },
  {
    path: "/health-analyse/activity-level",
    component: _ActivityLevel,
    progressStep: 0,
  },
  {
    path: "/health-analyse/training-days",
    component: _TrainingDays,
    progressStep: 0,
  },
  {
    path: "/health-analyse/preferred-meals/categories",
    component: _MealCategories,
    progressStep: 0,
  },
  { path: healthAnalyseMealsRoute, component: _Meal, progressStep: 0 },
  {
    path: "/health-analyse/terms-info",
    component: _TermsInfo,
    progressStep: 0,
  },
  { path: "/health-analyse/welcome", component: Welcome, progressStep: 2 },
];

//

export default HEALTH_ANALYSE_ROUTES;
