import axios from "axios";
import ApiService from ".";
import { createPartner } from "redux/coach/coach.sagas";

const BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_BASE_URL_DEVELOPMENT;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_BASE_URL_STAGING;
  else if (process.env.REACT_APP_ENV === "production") {
    return process.env.REACT_APP_BASE_URL_PRODUCTION;
  }
};

const FILE_BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL;
};

const FILE_BASE_URL_S3 = () => {
  if (process.env.REACT_APP_ENV === "development")
    return process.env.REACT_APP_FILES_DEVELOPMENT_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "staging")
    return process.env.REACT_APP_FILES_STAGING_BASE_URL_S3;
  else if (process.env.REACT_APP_ENV === "production")
    return process.env.REACT_APP_FILES_PRODUCTION_BASE_URL_S3;
};

const client = new ApiService({ baseURL: BASE_URL(), loginType: "user" });
const coach = new ApiService({ baseURL: BASE_URL(), loginType: "trainer" });
const customCoachApi = new ApiService({
  baseURL: BASE_URL(),
  headers: {
    "Content-Type": "application/json",
    "x-api-Key":
      "uEbwWdT5hhgikMjeSfOf5UwxnK9fPZRMveP5t3lFEyuR7rlKlixSs53wlrU9Vj9zsbnVCrlkrbCzcIap7hmjgjcGFPXcFNzELRb0dG3LrL2TWGkuKJH88QrCtLtsg0fw",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjAyNTY1ZTg2MDUxMGM1MjQwZmRlYjMxNDI3MmI3ZWQ1NmRmOTg3YzZhM2MxZGE2NDE1MzM1NzEzZWIwN2QwMDU4MDc2MWY4NDNkMDM1N2E2In0.eyJhdWQiOiIzIiwianRpIjoiMDI1NjVlODYwNTEwYzUyNDBmZGViMzE0MjcyYjdlZDU2ZGY5ODdjNmEzYzFkYTY0MTUzMzU3MTNlYjA3ZDAwNTgwNzYxZjg0M2QwMzU3YTYiLCJpYXQiOjE3NDIzMzE0MDksIm5iZiI6MTc0MjMzMTQwOSwiZXhwIjoxNzczODY3NDA5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.HmvRiP0YJAL8r6IBvcNKkN1HwaYPvaY0RFHdg525aS3_ehOhCJc8o9d7mHc2aCZWbgslZJoBLSfPlzHIWIPMJ4Nafv1GpfXCW2obhZ5AmE7Btt8ynnEmgTASkJssqEEd2ZDMySgCRDFdZJRuIAm8s-ygGMmqrnWQO0aO2VAjoS8vkbaimJMke_gx2I9MIsYvxlybekHaVhj_UR2wortv1VXbCd1b1OHFxCqnq-BDiHS6M_BIeFIpic-xWyJsvVo1dHMVDhaLnK8AMtbZ9eEVHFBKJBHoYqxonQetC_YqpI2bYLq80vSn21X6jocfqzIPtKBuvq-GILUm3YZMpnY_-UifndjUark-215_A_K6P93Ld-jtKfhhBrgsXhWbUixXcPSRPTmG7Rl7GdmFVmBsNpMMQL27L0nncY1XdPCBR5pZZEuZL68ZWWsOEBRJVMesMWMoBhUz6nlb4Ztp3ayhBhaSyAzZHTXd9ebDAo34ynn71mFHgL-bCJTf7pHC-V_R71C6lKC5LcHEXiBDdaoim97UbaPSoyGKHmsBk7qIyyTOW-Kp-lJ8i84ca2aurFC2-CR9q4dDdwJcYxEeRCRTianKB_YMv_hc-RTXu-y3hTDccZErMb53TBkth25W95coHyQLF4iWgs2LQSYG4bAZuh75X7olYWpVGLHJgnOG-E4",
  },
});
const AdminCoachApi = axios.create({
  baseURL: "https://admin.foodiary.app/api/",
  headers: {
    Authorization:
      "Bearer 7b9a1f3c0e5d8a2b6f0c9e2a1d3e9b4c8d1a6f3c5e0d8a2b6f0c9e2a1d3e9b4c8",
    "x-api-Key":
      "uEbwWdT5hhgikMjeSfOf5UwxnK9fPZRMveP5t3lFEyuR7rlKlixSs53wlrU9Vj9zsbnVCrlkrbCzcIap7hmjgjcGFPXcFNzELRb0dG3LrL2TWGkuKJH88QrCtLtsg0fw",
  },
});
const CustomCoachApi = axios.create({
  baseURL: "https://admin.foodiary.app/api/",
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "Bearer 7b9a1f3c0e5d8a2b6f0c9e2a1d3e9b4c8d1a6f3c5e0d8ahwrthetw2b6f0c9e2a1d3e9b4c8",
  },
});

const PartnerCustomApi = axios.create({
  baseURL: "https://app.foodiary.app/api/marketingportal/partner/create",
  headers: {
    "x-api-key":
      "uEbwWdT5hhgikMjeSfOf5UwxnK9fPZRMveP5t3lFEyuR7rlKlixSs53wlrU9Vj9zsbnVCrlkrbCzcIap7hmjgjcGFPXcFNzELRb0dG3LrL2TWGkuKJH88QrCtLtsg0fw",
    "Content-Type": "multipart/form-data",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjAyNTY1ZTg2MDUxMGM1MjQwZmRlYjMxNDI3MmI3ZWQ1NmRmOTg3YzZhM2MxZGE2NDE1MzM1NzEzZWIwN2QwMDU4MDc2MWY4NDNkMDM1N2E2In0.eyJhdWQiOiIzIiwianRpIjoiMDI1NjVlODYwNTEwYzUyNDBmZGViMzE0MjcyYjdlZDU2ZGY5ODdjNmEzYzFkYTY0MTUzMzU3MTNlYjA3ZDAwNTgwNzYxZjg0M2QwMzU3YTYiLCJpYXQiOjE3NDIzMzE0MDksIm5iZiI6MTc0MjMzMTQwOSwiZXhwIjoxNzczODY3NDA5LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.HmvRiP0YJAL8r6IBvcNKkN1HwaYPvaY0RFHdg525aS3_ehOhCJc8o9d7mHc2aCZWbgslZJoBLSfPlzHIWIPMJ4Nafv1GpfXCW2obhZ5AmE7Btt8ynnEmgTASkJssqEEd2ZDMySgCRDFdZJRuIAm8s-ygGMmqrnWQO0aO2VAjoS8vkbaimJMke_gx2I9MIsYvxlybekHaVhj_UR2wortv1VXbCd1b1OHFxCqnq-BDiHS6M_BIeFIpic-xWyJsvVo1dHMVDhaLnK8AMtbZ9eEVHFBKJBHoYqxonQetC_YqpI2bYLq80vSn21X6jocfqzIPtKBuvq-GILUm3YZMpnY_-UifndjUark-215_A_K6P93Ld-jtKfhhBrgsXhWbUixXcPSRPTmG7Rl7GdmFVmBsNpMMQL27L0nncY1XdPCBR5pZZEuZL68ZWWsOEBRJVMesMWMoBhUz6nlb4Ztp3ayhBhaSyAzZHTXd9ebDAo34ynn71mFHgL-bCJTf7pHC-V_R71C6lKC5LcHEXiBDdaoim97UbaPSoyGKHmsBk7qIyyTOW-Kp-lJ8i84ca2aurFC2-CR9q4dDdwJcYxEeRCRTianKB_YMv_hc-RTXu-y3hTDccZErMb53TBkth25W95coHyQLF4iWgs2LQSYG4bAZuh75X7olYWpVGLHJgnOG-E4",
  },
});
const ApiCollections = {
  // *********** Auth Module ********
  trainerSignUp: (params) => client.imgPost("trainerSignup", params),
  signIn: (params) => client.imgPost("trainerLogin", params),
  existingPlanSignIn: (params) => client.imgPost("trainerUserLogin", params),
  changePassword: (params) => client.post("reset-password", params),
  getTrainerProfile: (params) => coach.post("app/user/showData", params),
  trainerDetailsEdit: (params) =>
    coach.imgPost("app/user/trainerUpdate", params),
  registrationPart1: (params) => client.post("register", params),

  // *********** Coach Module ********
  getCoachData: (params) => coach.imgPost("coach/data", params),
  getTrainerID: (params) => coach.imgPost("coach_website_url/trainer", params),
  // *********** Nutrition Module ********
  getUserData: () => client.get("app/nutritionconcept"),
  getNutritionType: (params) => client.post("app/nutritiontype/show", params),
  getAllNutritionTypes: (params) =>
    client.post("app/nutritiontype/nutritionTypeList", params),
  updateNutritionType: (params) =>
    client.post("app/nutritiontype/nutritionTypeUpdate", params),
  updateNutritionCalories: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/caloriesUpdate",
      params
    ),
  getNutritionAdvantages: (params) =>
    client.post("app/nutritiontype/advantagesData", params),
  getNutritionRecipes: (params) =>
    client.post("app/nutritiontype/receipeData", params),
  getWeekWiseCaloriesData: (params) =>
    client.post("coachdashboard/clients/nutritiontype/weekWiseData", params),
  getMacroNutrientsDonutData: (params) =>
    client.post("app/nutritiontype/pieChartData", params),
  getMacroNutrientsPieChartData: (params) =>
    client.postWithoutAuthorization(
      BASE_URL(),
      "coachdashboard/clients/nutritiontype/getPieChartData",
      params
    ),

  getAllRefunds: () => client.get("app/coachingNutritionCourse/getAllRefunds"),
  saveTrainerLeads: (params) => client.post("trainerLeads/create", params),

  // *********** Health Score Module ********
  getSupplements: () => client.get("app/coachSupplement"),
  addProUserDataMailChimp: (params) =>
    client.post("app/mailchimpdata/addProUserDataMailchimp", params),
  addBasicUserDataMailChimp: (params) =>
    client.post("app/mailchimpdata/addBasicUserDataMailchimp", params),
  deleteMailChimpUserDetails: (params) =>
    client.post("app/mailchimpdata/deleteUserDetails", params),
  updateUserSubscription: (params) => client.post("app/coach/stripe", params),
  saveKooperationForm: (params) =>
    customCoachApi.post("create-affiliate", params),
  addAffiliateProgram: (params) =>
    customCoachApi.post("add-affiliate-program", params),
  AdminCoachQRCode: (params) =>
    AdminCoachApi.post("qr-code/create.php", params),
  createPartner: (params) => PartnerCustomApi.post("", params),
  createContract: (params) =>
    AdminCoachApi.post("partnership/create-contract.php", params),
  sendTransactionalEmail: (params) =>
    CustomCoachApi.post("mandrill/send_transactional_mail.php", params),
};

export { FILE_BASE_URL, FILE_BASE_URL_S3 };
export default ApiCollections;
