import * as Styles from "./welcome.styles";

import FoodIconOne from "assets/images/Aok-Foodiary-1.jpg";
import FoodIconTwo from "assets/images/Barmer-Foodiary.jpg";
import FoodIconThree from "assets/images/Bkk-Foodiary.jpg";
import FoodIconFour from "assets/images/DAK-foodiary-1.jpg";
import FoodIconFive from "assets/images/mhplus-foodiary.jpg";

function Welcome() {
  return (
    <Styles.MainContainer>
      <div className="label">Danke!</div>
      <div className="title">Erfolgreich.</div>
      <div className="subtitle">
        Ich habe deine Informationen bekommen und werde jetzt deinen
        Ernährungsplan erstellen und alles für dich vorbereiten.
      </div>
      <div className="subtitle2">UNTERSTÜTZ DURCH:</div>
      <div className="image-row">
        <img src={FoodIconOne} alt="" />
        <img src={FoodIconThree} alt="" />
        <img src={FoodIconFour} alt="" />
        <img src={FoodIconFive} alt="" />
        <img src={FoodIconTwo} alt="" />
      </div>
    </Styles.MainContainer>
  );
}

export default Welcome;
