import CoachActionTypes from "redux/coach/coach.types";

const DUMMY_FEEDBACK = [
  {
    trainerID: 1,
    rating: 5,
    picture: "coach_feedback/Jonas_1667466947ae6g0HvukQ.jpg",
    text: "12 Wochen vergingen im Flug! Zunächst möchte ich die hervorragende Betreuung hervorheben, mein Berater hat sich immer die Zeit für mich genommen. Die App mit den Rezepten ist wirklich klasse. Dadurch konnte ich einfach planen und das Essen, was mir geschmeckt hat.",
    prename: "Jonas",
    lastname: "Schlenker",
    birthday: null,
  },
  {
    trainerID: 1,
    rating: 5,
    picture: "coach_feedback/Sarah_1667467073Rqb6nhsGfm.jpg",
    text: "Über eine Weiterempfehlung in meinem Fitnessstudio bin ich auf Foodiary aufmerksam geworden. Das Angebot hat mir wirklich super gefallen. In meinem Coaching konnte ich nicht nur die ersten Schritte mit meinem Coach gehen, sondern auch viel über die Ernährung lernen. ☺️",
    prename: "Sarah",
    lastname: "Ostwald",
    birthday: null,
  },
  {
    trainerID: 1,
    rating: 5,
    picture: "coach_feedback/Michael_16674673242hzxq8uMY0.jpg",
    text: "Als IT-Unternehmer ist mir die Herausforderung bewusst, eine schöne und zugleich unterstützende Anwendung zu entwickeln. 👍 Foodiary hat das super umgesetzt und das Gesamtkonzept mit dem Coaching konnte mir viel Wissen vermitteln.",
    prename: "Michael",
    lastname: "Hochkofler",
    birthday: null,
  },
];

const getFeedbacks = (coach_feedback, dummyFeedback) => {
  let feedback = [];

  if (coach_feedback.length === 0) {
    feedback = dummyFeedback;
  }
  if (coach_feedback.length > 0) {
    feedback = coach_feedback;
    if (coach_feedback.length === 1) {
      feedback = [...coach_feedback, dummyFeedback.at(-1)];
    }
  }

  return feedback;
};

const INITIAL_STATE = {
  trainer: null,
  coach: null,
  feedback: [],
  error: null,
  loading: false,
  saveTrainerLeads: null,
  isTrainerLeadSaving: false,
};

const coachReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CoachActionTypes.COACH_DATA_SUCCESS:
      return {
        ...state,
        coach: action.payload.data,
        feedback: getFeedbacks(action.payload.coach_feedback, DUMMY_FEEDBACK),
      };
    case CoachActionTypes.TRAINER_ID_SUCCESS:
      return {
        ...state,
        trainer: action.payload,
      };
    case CoachActionTypes.COACH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CoachActionTypes.LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case CoachActionTypes.LOADING_STOP:
      return {
        ...state,
        loading: false,
      };

    case CoachActionTypes.TRAINER_LEADS_SUCCESS:
      return {
        ...state,
        saveTrainerLeads: action.payload,
      };
    case CoachActionTypes.TRAINER_LEADS_LOADING_START:
      return {
        ...state,
        isTrainerLeadSaving: true,
      };
    case CoachActionTypes.TRAINER_LEADS_LOADING_STOP:
      return {
        ...state,
        isTrainerLeadSaving: false,
      };
    case CoachActionTypes.KOOPERATION_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CoachActionTypes.KOOPERATION_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default coachReducer;
