import { useHistory } from "react-router-dom";
import { getRouteName } from "helper/routeHelper";

const mealsRoute = "/onboarding/preferred-meals/:mealId";
const healthAnalyseMealsRoute = "/health-analyse/preferred-meals/:mealId";
// Define route paths directly in the hook to avoid circular imports
const ONBOARDING_ROUTES = [
  "/app-erstellen",
  "/onboarding/gender",
  "/onboarding/goal",
  "/onboarding/body-details",
  "/onboarding/daily-routine",
  "/onboarding/activity-level",
  "/onboarding/training-days",
  "/onboarding/preferred-meals/categories",
  "/onboarding/preferred-meals/:mealId", // Special case for dynamic route
  "/onboarding/terms-info",
  "/onboarding/payment",
  "/onboarding/set-password",
  "/onboarding/nutrition-type",
  "/onboarding/nutrition-recipes",
  "/onboarding/weekly-calories",
  "/onboarding/macro-nutrients",
  "/onboarding/done",
];
//onboarding/goal

const HEALTHANALYSE_ROUTES = [
  "/health-analyse",
  "/health-analyse/gender",
  "/health-analyse/goal",
  "/health-analyse/body-details",
  "/health-analyse/daily-routine",
  "/health-analyse/activity-level",
  "/health-analyse/training-days",
  "/health-analyse/preferred-meals/categories",
  "/health-analyse/preferred-meals/:mealId",
  "/health-analyse/terms-info",
  "/health-analyse/welcome",
];

function useRouteNavigation() {
  const history = useHistory();

  const ONBOARDING = "onboarding";
  const HEALTH_ANALYSE = "health-analyse";

  // Determine which flow we're in based on the current path
  const getFlowType = (currentPath) => {
    if (currentPath && currentPath.includes("health-analyse")) {
      return HEALTH_ANALYSE;
    }
    return ONBOARDING;
  };

  // Internal helper to find the next route in an array
  const findNextRoute = (currentPath, routes) => {
    console.log(
      "🚀 ~ findNextRoute ~ currentPath, routes:",
      currentPath,
      routes
    );
    // Handle special case for the meal route
    if (
      currentPath.includes("/preferred-meals/") &&
      !currentPath.includes("/categories")
    ) {
      // Extract meal number from path
      const mealIdMatch = currentPath.match(/\/preferred-meals\/(\d+)/);
      if (mealIdMatch) {
        const currentMeal = parseInt(mealIdMatch[1]);
        if (currentMeal === 5) {
          // After the last meal, go to the next path after the meal template
          const mealTemplateIndex = routes.indexOf(
            "/onboarding/preferred-meals/:mealId"
          );
          return routes[mealTemplateIndex + 1];
        } else {
          // Go to next meal number
          return currentPath.replace(/\/(\d+)$/, `/${currentMeal + 1}`);
        }
      }
    }

    // Normal route navigation
    const index = routes.indexOf(currentPath);
    if (index !== -1 && index < routes.length - 1) {
      const nextRoute = routes[index + 1];
      // If next route is the meal template, replace with first meal
      if (nextRoute.includes(":mealId")) {
        return nextRoute.replace(":mealId", "1");
      }
      return nextRoute;
    }
    return null;
  };

  // Internal helper to find the previous route in an array
  const findPreviousRoute = (currentPath, routes) => {
    // Handle special case for the meal route
    if (
      currentPath.includes("/preferred-meals/") &&
      !currentPath.includes("/categories")
    ) {
      // Extract meal number from path
      const mealIdMatch = currentPath.match(/\/preferred-meals\/(\d+)/);
      if (mealIdMatch) {
        const currentMeal = parseInt(mealIdMatch[1]);
        if (currentMeal === 1) {
          // Before the first meal, go to categories
          return currentPath.replace(/\/\d+$/, "/categories");
        } else {
          // Go to previous meal number
          return currentPath.replace(/\/(\d+)$/, `/${currentMeal - 1}`);
        }
      }
    }

    // Normal route navigation
    const index = routes.indexOf(currentPath);
    if (index > 0) {
      const prevRoute = routes[index - 1];
      // If previous route is the meal template, replace with last meal
      if (prevRoute.includes(":mealId")) {
        return prevRoute.replace(":mealId", "5");
      }
      return prevRoute;
    }
    return null;
  };

  // Generic "get next page" function that works for any flow
  const getNextPage = (currentPath) => {
    const flowType = getFlowType(currentPath);

    const routes =
      flowType === HEALTH_ANALYSE ? HEALTHANALYSE_ROUTES : ONBOARDING_ROUTES;
    return findNextRoute(currentPath, routes);
  };

  // Generic "get previous page" function that works for any flow
  const getPreviousPage = (currentPath) => {
    const flowType = getFlowType(currentPath);
    const routes =
      flowType === HEALTH_ANALYSE ? HEALTHANALYSE_ROUTES : ONBOARDING_ROUTES;
    return findPreviousRoute(currentPath, routes);
  };

  // Handle navigation to next page with validation
  const handleNavigate = (currentPath) => {
    console.log("🚀 ~ handleNavigate ~ currentPath:", currentPath);
    // Navigate to next page
    const nextPage = getNextPage(currentPath);
    if (nextPage) {
      history.push(nextPage);
      return true;
    }
    return false;
  };

  // Handle navigation to previous page
  const handleNavigateBack = (currentPath) => {
    const flowType = getFlowType(currentPath);
    const previousPage = getPreviousPage(currentPath);

    // Check if we're at the start of a flow
    if (
      (flowType === HEALTH_ANALYSE &&
        previousPage === "/health-analyse/start") ||
      (flowType === ONBOARDING && previousPage === "/app-erstellen")
    ) {
      history.push("/" + getRouteName());
    } else if (previousPage) {
      history.push(previousPage);
    } else {
      history.goBack();
    }
  };

  // Utility for dynamic meal pages
  const nextMealPage = (currentPath, currentMeal) => {
    const flowType = getFlowType(currentPath);
    currentMeal = parseInt(currentMeal);
    if (flowType === HEALTH_ANALYSE) {
      if (currentMeal === 5) return handleNavigate(healthAnalyseMealsRoute);
      return history.push(
        healthAnalyseMealsRoute.replace(":mealId", currentMeal + 1)
      );
    } else {
      if (currentMeal === 5) return handleNavigate(mealsRoute);
      return history.push(mealsRoute.replace(":mealId", currentMeal + 1));
    }

    //  export const getNextMealPage = (currentMeal) => {
    //    currentMeal = parseInt(currentMeal);

    //    if (currentMeal === 5) return getNextOnboardingPage(mealsRoute);
    //    return mealsRoute.replace(":mealId", currentMeal + 1);
    //  };
  };

  // Utility for dynamic meal pages
  const previousMealPage = (currentPath, currentMeal) => {
    const flowType = getFlowType(currentPath);
    currentMeal = parseInt(currentMeal);

    if (flowType === HEALTH_ANALYSE) {
      //  currentMeal = parseInt(currentMeal);

      if (currentMeal === 1) return handleNavigateBack(healthAnalyseMealsRoute);
      return history.push(
        healthAnalyseMealsRoute.replace(":mealId", currentMeal - 1)
      );
    } else {
      if (currentMeal === 1) return handleNavigateBack(mealsRoute);
      return history.push(mealsRoute.replace(":mealId", currentMeal - 1));
    }
  };
  // Function to get the appropriate storage key prefix based on flow
  const getStoragePrefix = (currentPath) => {
    return getFlowType(currentPath) === HEALTH_ANALYSE
      ? "HEALTH-ANALYSE_"
      : "ONBOARDING_";
  };

  return {
    handleNavigate,
    handleNavigateBack,
    nextMealPage,
    previousMealPage,
    getStoragePrefix,
  };
}

export { useRouteNavigation };
