import backIcon from "assets/images/back-icon.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import * as Styles from "./PreferredMealsStyles";
import { Checkbox } from "antd";
import { meal_categories } from "./mealsData";
import { categoryIcons } from "./iconMappings";
import {
  getOnboardingStorage,
  setOnboardingStorage,
  PREFERRED_MEALS_STEP,
} from "../storage";
import { useRouteNavigation } from "hooks/useRouteNavigation";

function MealCategories({ currentPath }) {
  const { handleNavigate, handleNavigateBack } = useRouteNavigation();

  const storage = getOnboardingStorage(PREFERRED_MEALS_STEP);
  const [validationError, setValidationError] = useState(null);

  const [selectedPreference, setSelectedPreference] = useState(
    storage.categoryOption || {}
  );
  const options = meal_categories.map((category) => ({
    ...category,
    icon: categoryIcons[category.value],
  }));

  useEffect(() => {
    setValidationError(null);
  }, [selectedPreference]);

  const handleNextPage = () => {
    if (!selectedPreference.value) {
      return setValidationError("Wähle eine Kategorie");
    }

    // only reset meals if the category was updated
    const shouldResetMeals =
      storage.categoryOption &&
      selectedPreference.value !== storage.categoryOption.value;

    setOnboardingStorage(PREFERRED_MEALS_STEP, {
      categoryOption: selectedPreference,
      meals: shouldResetMeals ? {} : storage.meals,
    });

    handleNavigate(currentPath);
  };

  const goBack = () => {
    handleNavigateBack(currentPath);
  };

  return (
    <div>
      <div className="step-header">
        <div className="header-content">
          <Link to="#" onClick={goBack}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">
              Vegan, Vegetarisch oder Fleischhaltig?
            </div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">
            Weiter
          </span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className="section-titles-wrapper">
            <div className="primary-title">Gibt es Präferenzen?</div>
            <div className="accent-subtitle">
              Nenne uns, ob du im Alltag eher auf tierische Produkte verzichtest
              oder eine fleischhaltige Ernährungsweise bevorzugst?
            </div>
            <div className="small-subtitle">
              Welche Art der Ernährung bevorzugst du?
            </div>
            {validationError ? (
              <div className="error-message">{validationError}</div>
            ) : (
              ""
            )}
          </div>
          <div className="meal-options">
            {options.map((option) => (
              <Styles.MealOption
                key={option.value}
                onClick={() => setSelectedPreference(option)}
                selected={selectedPreference.value === option.value}
              >
                <div className="option-content">
                  <div className="icon">
                    <img src={option.icon} alt="" />
                  </div>
                  <div className="name">{option.label}</div>
                </div>
                <div className="option-checkbox">
                  <Checkbox
                    checked={selectedPreference.value === option.value}
                    onChange={() => setSelectedPreference(option)}
                  />
                </div>
              </Styles.MealOption>
            ))}
          </div>
        </Styles.MainContainer>
      </div>
    </div>
  );
}

export default MealCategories;
