import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  vorname: Yup.string().required("Vorname ist erforderlich"),
  nachname: Yup.string().required("Nachname ist erforderlich"),
  strasse: Yup.string().required("Straße ist erforderlich"),
  hausnummer: Yup.string().required("Hausnummer ist erforderlich"),
  plz: Yup.string().required("PLZ ist erforderlich"),
  ort: Yup.string().required("Ort ist erforderlich"),
  email: Yup.string()
    .email("Ungültige E-Mail-Adresse")
    .required("E-Mail ist erforderlich"),
  kontaktnummer: Yup.string().required("Kontaktnummer ist erforderlich"),
  iban: Yup.string().required("IBAN ist erforderlich"),
  bic: Yup.string().required("BIC ist erforderlich"),
  agbAccepted: Yup.boolean().oneOf([true], "Sie müssen die AGB akzeptieren"),
  geschlecht: Yup.string().required("Geschlecht ist erforderlich"),

  privacyAccepted: Yup.boolean().oneOf(
    [true],
    "Sie müssen die Datenschutzerklärung akzeptieren"
  ),
  contract_duration: Yup.string().required("Vertragslaufzeit ist erforderlich"),

  // Conditional fields based on registration type
  firmenname: Yup.string().when("registrationType", {
    is: "unternehmen",
    then: (schema) => schema.required("Firmenname ist erforderlich"),
    otherwise: (schema) => schema.notRequired(),
  }),
  steuernummer: Yup.string().when("registrationType", {
    is: "unternehmen",
    then: (schema) => schema.required("Steuernummer ist erforderlich"),
    otherwise: (schema) => schema.notRequired(),
  }),
  unternehmenswebseite: Yup.string().when("registrationType", {
    is: "unternehmen",
    then: (schema) => schema.required("Unternehmenswebseite ist erforderlich"),
    otherwise: (schema) => schema.notRequired(),
  }),
  position: Yup.string().when("registrationType", {
    is: "unternehmen",
    then: (schema) => schema.required("Position ist erforderlich"),
    otherwise: (schema) => schema.notRequired(),
  }),
  unternehmensart: Yup.string().when("registrationType", {
    is: "unternehmen",
    then: (schema) => schema.required("Unternehmensart ist erforderlich"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
