import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  padding-top: 5%;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #44554c;
  .gap {
    gap: 10px !important;
  }
  .subtitle {
    color: #44554c;
    max-width: 500px;
  }

  @media (min-width: 1000px) {
    width: 80%;
    margin: auto;
  }

  @media (min-width: 1300px) {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const TextContainer = styled.div`
  text-align: center;
`;
export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  align-self: center;
  gap: 20px;
  margin: auto;
  @media screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 20px;
  }
  .column {
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 5px #0000001a;
    border-radius: 15px;
    padding: 30px 20px;
    text-align: center;
    .card-title {
      color: #142c1f;
      font-weight: 500;
    }
    .card-content {
      gap: 15px;
      display: flex;
      flex-direction: column;
    }
    .card-description {
      font-size: 14px;
      color: #44554c;
    }
  }
`;
