import backIcon from "assets/images/back-icon.svg";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import * as Styles from "./PreferredMealsStyles";
import { Checkbox } from "antd";
import { allMeals } from "./mealsData";
import { mealIcons } from "./iconMappings";
import {
  getOnboardingStorage,
  setOnboardingStorage,
  PREFERRED_MEALS_STEP,
} from "../storage";
import { useRouteNavigation } from "hooks/useRouteNavigation";

function Meal({ currentPath }) {
  const { handleNavigateBack, previousMealPage, nextMealPage } =
    useRouteNavigation();

  const storage = getOnboardingStorage(PREFERRED_MEALS_STEP);
  const storageMeals = storage.meals || {};
  const { mealId } = useParams();
  const mealNumber = `meal${mealId}`;
  const [mealCategory, setMealCategory] = useState("");
  const [selectedPreference, setSelectedPreference] = useState(
    storageMeals[mealNumber] || {}
  );
  const [validationError, setValidationError] = useState(null);

  const meals = ((allMeals[mealCategory] || {})[mealNumber] || []).map(
    (meal) => ({
      ...meal,
      icon: mealIcons[meal.value],
    })
  );

  const noMeals = !meals || meals.length === 0;

  useEffect(() => {
    // if (!storage.categoryOption) {
    //   // redirect to category selection page if category is not chosen yet
    //   return handleNavigateBack(currentPath);
    // }

    setMealCategory(storage.categoryOption.value);
    setSelectedPreference(storageMeals[mealNumber] || {});
  }, [mealId]);

  useEffect(() => {
    setValidationError(null);
  }, [selectedPreference]);

  const mealOptions = noMeals ? (
    <p>
      No meals found for category {mealCategory} and meal number {mealId}.
    </p>
  ) : (
    meals.map((option) => (
      <Styles.MealOption
        key={option.value}
        onClick={() => setSelectedPreference(option)}
        selected={selectedPreference.value === option.value}
      >
        <div className="option-content">
          <div className="icon bordered-icon">
            <img src={option.icon} alt="" />
          </div>
          <div className="name">{option.label}</div>
        </div>
        <div className="option-checkbox">
          <Checkbox
            checked={selectedPreference.value === option.value}
            onChange={() => setSelectedPreference(option)}
          />
        </div>
      </Styles.MealOption>
    ))
  );

  const handleNextPage = () => {
    if (!selectedPreference.value) {
      return setValidationError("Bitte wähle mindestens ein Gericht");
    }

    setOnboardingStorage(PREFERRED_MEALS_STEP, {
      ...storage,
      meals: {
        ...storage.meals,
        [mealNumber]: selectedPreference,
      },
    });

    nextMealPage(currentPath, mealId);
  };

  const goBack = () => {
    previousMealPage(currentPath, mealId);
  };

  return (
    <div>
      <div className="step-header">
        <div className="header-content">
          <Link to="#" onClick={goBack}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">Deine Präferenzen der Rezepte</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">
            Weiter
          </span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className="section-titles-wrapper">
            <div className="primary-title">
              Um deinen Plan weiter zu personalisieren passen wir deine
              Ernährungsform an.
            </div>
            <div className="accent-subtitle">
              Wähle das Rezept, welches dir am meisten zusagt. In der App
              erhältst du später 1700 Rezepte.
            </div>
            <div className="small-subtitle">Was isst du lieber?</div>
            {validationError ? (
              <div className="error-message">{validationError}</div>
            ) : (
              ""
            )}
          </div>
          <div className="meal-options">{mealOptions}</div>
        </Styles.MainContainer>
      </div>
    </div>
  );
}

export default Meal;
