import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as Styles from "./kooperation.styles";
import * as G from "styles/General.Styles";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import CopyIcon from "assets/images/copy-icon.svg";

const ErfolgreichPage = () => {
  const location = useLocation();
  const { qrCodeUrl, partnerUrl } = location.state || {};
  const [displayedQrUrl, setDisplayedQrUrl] = useState("");
  const [displayedPartnerUrl, setDisplayedPartnerUrl] = useState("");

  const copyCode = (code) => () => {
    navigator.clipboard.writeText(code);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top
  }, []);
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;

      if (qrCodeUrl) {
        // For mobile: show fewer characters
        if (isMobile) {
          setDisplayedQrUrl(qrCodeUrl.slice(0, 27) + "...");
        } else {
          setDisplayedQrUrl(qrCodeUrl.slice(0, qrCodeUrl.length - 20) + "...");
        }
      }

      if (partnerUrl) {
        if (isMobile) {
          setDisplayedPartnerUrl(partnerUrl.slice(0, 27) + "...");
        } else {
          setDisplayedPartnerUrl(partnerUrl);
        }
      }
    };

    // Call once on mount
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, [qrCodeUrl, partnerUrl]);

  return (
    <>
      <Styles.Container>
        <G.ContainerNavbar>
          <StickyNavbar openSurveyModal={() => {}} />
        </G.ContainerNavbar>
        <Styles.HeaderContent>
          <Styles.Chip className="font-medium">DEIN START</Styles.Chip>
          <Styles.HeaderTitle className="ct-text-block font-bold color-dark text-center text-5xl">
            Die Kooperation
            <br />
            wurde angelegt
          </Styles.HeaderTitle>
          <Styles.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
            Herzlichen Glückwunsch! Die Partnerschaft wurde erfolgreich
            angelegt.
          </Styles.HeaderDescription>
        </Styles.HeaderContent>
        <Styles.SubContainer>
          <Styles.FormContainer>
            <Styles.HeaderTitle className="ct-text-block font-bold color-dark text-3xl">
              Deine Partner-URL
            </Styles.HeaderTitle>
            <Styles.HeaderDescription className="ct-text-block color-paragraph text-xs mb-10 ">
              Mit dieser URL und deinem QR-Code können wir deine Teilnehmer
              zuordnen und deine Werbeprovision ausbezahlen.
            </Styles.HeaderDescription>

            <Styles.FieldContainer className="mb-6">
              <Styles.FieldLabel>AFFILIATE LINK:</Styles.FieldLabel>
              <Styles.SimpleInputField
                type="text"
                name="affiliateLink"
                placeholder="AFFILIATE LINK:"
                value={displayedPartnerUrl}
                disabled
              />
              <Styles.CopyButton onClick={copyCode(partnerUrl)}>
                <img src={CopyIcon} alt="copy" />
              </Styles.CopyButton>
            </Styles.FieldContainer>

            <Styles.FieldContainer>
              <Styles.FieldLabel>QR-CODE:</Styles.FieldLabel>
              <Styles.SimpleInputField
                type="text"
                name="qrCode"
                placeholder="QR-CODE:"
                value={displayedQrUrl}
                disabled
              />
              <Styles.CopyButton onClick={copyCode(qrCodeUrl)}>
                <img src={CopyIcon} alt="copy" />
              </Styles.CopyButton>
            </Styles.FieldContainer>
          </Styles.FormContainer>
        </Styles.SubContainer>
      </Styles.Container>
      <FooterNew />
    </>
  );
};

export default ErfolgreichPage;
