import { KOOPERATION } from "routes/routes";

const CoachTypes = {
  LOADING_START: "LOADING_START",
  LOADING_STOP: "LOADING_STOP",

  TRAINER_ID_REQUEST: "TRAINER_ID_REQUEST",
  TRAINER_ID_SUCCESS: "TRAINER_ID_SUCCESS",
  TRAINER_ID_FAILURE: "TRAINER_ID_FAILURE",

  COACH_DATA_REQUEST: "COACH_DATA_REQUEST",
  COACH_DATA_SUCCESS: "COACH_DATA_SUCCESS",
  COACH_DATA_FAILURE: "COACH_DATA_FAILURE",

  TRAINER_LEADS_REQUEST: "TRAINER_LEADS_REQUEST",
  TRAINER_LEADS_SUCCESS: "TRAINER_LEADS_SUCCESS",
  TRAINER_LEADS_FAILURE: "TRAINER_LEADS_FAILURE",

  TRAINER_LEADS_LOADING_START: "TRAINER_LEADS_LOADING_START",
  TRAINER_LEADS_LOADING_STOP: "TRAINER_LEADS_LOADING_STOP",
  KOOPERATION_FORM_REQUEST: "KOOPERATION_FORM_REQUEST",
  KOOPERATION_FORM_SUCCESS: "KOOPERATION_FORM_SUCCESS",
  KOOPERATION_FORM_FAILURE: "KOOPERATION_FORM_FAILURE",
  ADD_AFFILIATE_PROGRAM: "ADD_AFFILIATE_PROGRAM",
  ADMIN_COACH_QR_CODE: "ADMIN_COACH_QR_CODE",
  CREATE_PARTNER: "CREATE_PARTNER",
  CREATE_CONTRACT: "CREATE_CONTRACT",
  SEND_TRANSACTIONAL_EMAIL: "SEND_TRANSACTIONAL_EMAIL",
};

export default CoachTypes;
